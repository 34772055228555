/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import axios from 'axios';
import {
  SITS_API_URL,
} from '../Config';
import { currentFYyear } from '../constants';
import { defaultSortValueforApi } from '../components/searchpage/filters/Sortfiltercomponent';
import { errorLogging } from '../applicationDataLogs/DataLogsforApplicationInsights';

const retrievePendingdata = async (localeCode, success) => {
  const data = {
    locale_code: localeCode,
    financialYear: currentFYyear,
  };
  return axios
    .post(`${SITS_API_URL}/api/StartPage/PendingTranslationCount`, data)
    .then((response) => {
      success(response); // The response data is assign to success function
    }).catch((errors) => {
      const errorData = {
        message: 'Error log details',
        apiName: 'StartPage/PendingTranslationCount',
        apiPayload: data,
        apiResponse: errors.response.data,
        userId: window.sessionStorage.getItem('userId'),
      };
      errorLogging(errorData);
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrieveGraphdata = async (localeCode, selectedYear, success) => {
  const data = {
    locale_code: localeCode,
    financialYear: selectedYear,
  };
  return axios
    .post(`${SITS_API_URL}/api/StartPage/CountOfTextsAndWordsOfNewAndReleasedStatus`, data)
    .then((response) => {
      success(response); // The response data is assign to success function
    }).catch((errors) => {
      const errorData = {
        message: 'Error log details',
        apiName: 'StartPage/CountOfTextsAndWordsOfNewAndReleasedStatus',
        apiPayload: data,
        apiResponse: errors.response.data,
        userId: window.sessionStorage.getItem('userId'),
      };
      errorLogging(errorData);
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrievedueindata = async (
  selectedlocal,
  selectedFilters,
  filters,
  success,
) => {
  const data = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    category: selectedFilters.categoryfilters,
    sort: selectedFilters.Sortfilters === undefined
    || selectedFilters.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFilters.Sortfilters[0],
  };
  axios
    .post(
      `${SITS_API_URL}/api/StartPage/dueIn`,
      data,
    )
    .then((response) => {
      success(response, filters); // The response data is assign to success function
    }).catch((errors) => {
      const errorData = {
        message: 'Error log details',
        apiName: 'StartPage/dueIn',
        apiPayload: data,
        apiResponse: errors.response.data,
        userId: window.sessionStorage.getItem('userId'),
      };
      errorLogging(errorData);
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrievedueindataCount = async (
  selectedlocal,
  selectedFilters,
  filters,
  success,
) => {
  const data = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    category: selectedFilters.categoryfilters,
    sort: selectedFilters.Sortfilters === undefined
    || selectedFilters.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFilters.Sortfilters[0],
  };
  axios
    .post(
      `${SITS_API_URL}/api/StartPage/dueInTextsFiltersCount`,
      data,
    )
    .then((response) => {
      success(response, filters, selectedFilters);
    }).catch((errors) => {
      const errorData = {
        message: 'Error log details',
        apiName: 'StartPage/dueInTextsFiltersCount',
        apiPayload: data,
        apiResponse: errors.response.data,
        userId: window.sessionStorage.getItem('userId'),
      };
      errorLogging(errorData);
      if (errors.response) {
        success(errors.response);
      } else if (errors.request) {
        success(errors.request); // The Error request is assign to successmethod
      } else {
        success(errors.message); // The Error message is assign to successmethod
      }
    });
};

const retrieveDueinDataCountForFilter = async (
  selectedlocal,
  selectedFilters,
  success,
) => {
  const dueinCountPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: [],
    status: selectedFilters.Statusfilters,
    sort: '',
    category: selectedFilters.categoryfilters,
  };
  const objecttypePayload = {
    locale_code: selectedlocal,
    object_type: [],
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: '',
    category: selectedFilters.categoryfilters,
  };
  const statusPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: [],
    sort: '',
    category: selectedFilters.categoryfilters,
  };
  const categoryPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: '',
    category: [],
  };

  const itemsCountReqs = [
    `${SITS_API_URL}/api/StartPage/dueInTextsFiltersCount`,
    `${SITS_API_URL}/api/StartPage/DueInObjectTypeFiltersCount`,
    `${SITS_API_URL}/api/StartPage/DueInStatusFiltersCount`,
    `${SITS_API_URL}/api/StartPage/DueInCategoryFiltersCount`,

  ];

  const dueinRequest = axios.post(
    itemsCountReqs[0],
    dueinCountPayload,
  );
  const objecttypeRequest = axios.post(
    itemsCountReqs[1],
    objecttypePayload,
  );
  const statusRequest = axios.post(
    itemsCountReqs[2],
    statusPayload,
  );
  const categoryRequest = axios.post(
    itemsCountReqs[3],
    categoryPayload,
  );
  // eslint-disable-next-line max-len
  Promise.all([dueinRequest, objecttypeRequest, statusRequest, categoryRequest]).then((response) => {
    success(
      response[0].data,
      response[1].data,
      response[2].data,
      response[3].data,
    );
  });
};

export {
  retrievedueindata,
  retrievedueindataCount,
  retrieveGraphdata,
  retrievePendingdata,
  retrieveDueinDataCountForFilter,
};
