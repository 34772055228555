/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, {
  useEffect, useContext,
} from 'react';
import Cookies from 'js-cookie';
import './EditorBottomLayout.scss';
import EditorBottomLeftContainer from '../editorBottomLeftSection/editorBottomLeftContainer/EditorBottomLeftContainer';
import EditorBottomRightContainer from '../editorBottomRightSection/editorBottomRightContainer/EditorBottomRightContainer';
import SitsContext from '../../../context/SitsContext';

const EditorBottomLayout = (props) => {
  const sitsContext = useContext(SitsContext);

  useEffect(() => {
    // What i do in this useeffect
    // we first check if we have any values (height and width) in cookies, if values are there
    // then we assign those values to the editor whole bottom, left and right section
    // If those values are not there (null/undefined), then we assign whatever width/height the section
    // have at the moment
    // Another part we do is we are checking if carousel in the connected products has scrollbar, if
    // it has scroll bar, we set scrollSliderConnectedProd to true otherwise, we set it false
    const editorBottomSectionElement = document.getElementById('editorBottomSection');
    const bottomLeftSectionElement = document.getElementById('editorBottomLeft');
    const bottomRightSectionElement = document.getElementById('editorBottomRight');
    if ((Cookies.get('editorBottomHeight') !== undefined)
    && (Cookies.get('editorBottomHeight') !== null) && ((Cookies.get('editorBottomHeight') !== 0))) {
      editorBottomSectionElement.style.height = `${Cookies.get('editorBottomHeight')}px`;
      sitsContext.updateEditorBottomSectionHeight(editorBottomSectionElement.offsetHeight);
    } else if (editorBottomSectionElement !== undefined) {
      sitsContext.updateEditorBottomSectionHeight(editorBottomSectionElement.offsetHeight);
    }
    sitsContext.updateEditorResizeObj('resizeFlag', false);
    if ((Cookies.get('editorBottomLeftWidth') !== undefined)
    || (Cookies.get('editorBottomRightWidth') !== undefined)) {
      bottomLeftSectionElement.style.width = `${Cookies.get('editorBottomLeftWidth')}px`;
      bottomRightSectionElement.style.width = `${Cookies.get('editorBottomRightWidth')}px`;
      sitsContext.updateEditorBottomLeftWidth(bottomLeftSectionElement.offsetWidth);
      sitsContext.updateEditorBottomRightWidth(bottomRightSectionElement.offsetWidth - 5);
    } else {
      sitsContext.updateEditorBottomLeftWidth(bottomLeftSectionElement.offsetWidth);
      sitsContext.updateEditorBottomRightWidth(bottomRightSectionElement.offsetWidth - 5);
    }
  }, []);

  useEffect(() => {
    // Below logic gets executed when there is a change in mouse position between past and present While
    // resizing
    // Below resize flag is specific to the whole editor bottom height drag section
    // When you increase/decrease the height of the editor bottom section, this flag will be true
    // Inside if condition, we calculate the total height using resize value and assign it to the whole
    // editor bottom section
    // Cookies are updated and usestate(Soon will be changed to context store value) is also updated

    if (sitsContext.editorResizeObj.resizeFlag === true) {
      const bottomSectionElement = document.getElementById('editorBottomSection');
      // eslint-disable-next-line prefer-template, radix
      bottomSectionElement.style.height = (parseInt(getComputedStyle(bottomSectionElement, '').height) + props.editorResizeValue) + 'px';
      Cookies.set('editorBottomHeight', bottomSectionElement.offsetHeight);
      sitsContext.updateEditorBottomSectionHeight(bottomSectionElement.offsetHeight);
    }
  }, [props.editorResizeValue]);

  useEffect(() => {
    // Every time there is a change in the editor bottom section height, we call the function in the editor
    // page component and update the editor bottom height state in there. Once we have create store value,
    // this useeffect won't be needed
    props.updateEditorBottomHeight(sitsContext.editorBottomSectionHeight);
  }, [sitsContext.editorBottomSectionHeight]);

  // eslint-disable-next-line consistent-return
  const handleWindowSizeChange = () => {
    // Whenever there is a change in zoom level/ screen change, we execute this function and update the
    // state in this component and editor page component's state
    props.updateEditorBottomHeight(sitsContext.editorBottomSectionHeight);
    const editorBottomSectionElement = document.getElementById('editorBottomSection');
    if (editorBottomSectionElement !== undefined) {
      sitsContext.updateEditorBottomSectionHeight(editorBottomSectionElement.offsetHeight);
      props.updateEditorBottomHeight(editorBottomSectionElement.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const onMouseDownHandler = (e) => {
    const viewportHeight = window.innerHeight;
    const bottomSectionElement = document.getElementById('editorBottomSection');
    const thresholdValue = (10 * (bottomSectionElement.offsetHeight)) / 100;
    const topSectionHeight = thresholdValue + ((viewportHeight - (bottomSectionElement.offsetHeight)));
    if ((topSectionHeight) > (e.clientY)) {
      sitsContext.updateEditorResizeMousePos(e.clientY);
      sitsContext.updateEditorResizeObj('resizeFlag', true);
    } else if ((topSectionHeight) < (e.clientY)) {
      sitsContext.updateEditorResizeObj('resizeFlag', false);
    }
  };

  const onMouseUpHandler = () => {
    sitsContext.updateEditorResizeObj('resizeFlag', false);
  };

  // eslint-disable-next-line no-unused-vars
  const onMouseOverHandler = (e) => {
    const viewportHeight = window.innerHeight; // Whole page height
    const bottomSectionElement = document.getElementById('editorBottomSection');
    const editorLeftHeaderElement = document.getElementById('editorBottomLeftHeaderSection');
    const editorRightHeaderElement = document.getElementById('editorBottomRightHeaderSection');
    const thresholdValue = (10 * (bottomSectionElement.offsetHeight)) / 100;
    const topSectionHeight = thresholdValue + ((viewportHeight - (bottomSectionElement.offsetHeight)));
    if ((topSectionHeight) > (e.clientY)) {
      editorLeftHeaderElement.style.cursor = 'ns-resize';
      editorRightHeaderElement.style.cursor = 'ns-resize';
    } else if ((topSectionHeight) < (e.clientY)) {
      sitsContext.updateEditorResizeObj('resizeFlag', false);
      editorLeftHeaderElement.style.cursor = 'default';
      editorRightHeaderElement.style.cursor = 'default';
    }
    if ((sitsContext.editorResizeObj.resizeFlag === true)) {
      // Past mouse position - present mouse position => dx => 55 - 60 => -5, -> moving the cursor
      // towards bottom
      // Moving the cursor towards top => past mouse position - present mouse position =>
      // dx => 70 - 60 => 10,
      const dx = (sitsContext.editorResizeMousePos) - e.clientY; // 57 - 55 => 2, 57 - 59 = -2
      sitsContext.updateEditorResizeMousePos(e.clientY);
      // eslint-disable-next-line prefer-template, radix
      bottomSectionElement.style.height = (parseInt(getComputedStyle(bottomSectionElement, '').height) + dx) + 'px';
      sitsContext.updateEditorBottomSectionHeight(bottomSectionElement.offsetHeight);
      Cookies.set('editorBottomHeight', bottomSectionElement.offsetHeight);
    }
  };

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div>
      <div
        id="editorBottomSection"
        className="editor_bottom_layout bottom_container"
        onMouseDown={(e) => onMouseDownHandler(e)}
        onMouseUp={onMouseUpHandler}
        onMouseMove={(e) => onMouseOverHandler(e)}
      >
        <EditorBottomLeftContainer
          segmentSelected={props.segmentSelected}
        />
        <EditorBottomRightContainer
          segmentSelected={props.segmentSelected}
          mouseDownHandler={onMouseDownHandler}
          mouseUpHandler={onMouseUpHandler}
          mouseMoveHandler={onMouseOverHandler}
        />
      </div>
    </div>
  );
};

export default EditorBottomLayout;
