import { currentFYyear, previousFYyear } from '../../../constants';

const dummyDatainGraph = [
  {
    newdata: '2', publisheddata: '0', year: previousFYyear.toString(), month: 'Sep', monthvalue: 9,
  },
  {
    newdata: '7', publisheddata: '0', year: previousFYyear.toString(), month: 'Oct', monthvalue: 10,
  },
  {
    newdata: '0', publisheddata: '6', year: previousFYyear.toString(), month: 'Nov', monthvalue: 11,
  },
  {
    newdata: '3', publisheddata: '6', year: previousFYyear.toString(), month: 'Dec', monthvalue: 12,
  },
  {
    newdata: '5', publisheddata: '0', year: currentFYyear.toString(), month: 'Jan', monthvalue: 1,
  },
  {
    newdata: '1', publisheddata: '5', year: currentFYyear.toString(), month: 'Feb', monthvalue: 2,
  },
  {
    newdata: '4', publisheddata: '3', year: currentFYyear.toString(), month: 'Mar', monthvalue: 3,
  },
  {
    newdata: '0', publisheddata: '3', year: currentFYyear.toString(), month: 'Apr', monthvalue: 4,
  },
  {
    newdata: '7', publisheddata: '0', year: currentFYyear.toString(), month: 'May', monthvalue: 5,
  },
  {
    newdata: '1', publisheddata: '3', year: currentFYyear.toString(), month: 'Jun', monthvalue: 6,
  },
  {
    newdata: '4', publisheddata: '0', year: currentFYyear.toString(), month: 'Jul', monthvalue: 7,
  },
  {
    newdata: '0', publisheddata: '6', year: currentFYyear.toString(), month: 'Aug', monthvalue: 8,
  },
];

const initialDatainGraph = [
  {
    newdata: '0', publisheddata: '0', year: previousFYyear.toString(), month: 'Sep', monthvalue: 9,
  },
  {
    newdata: '0', publisheddata: '0', year: previousFYyear.toString(), month: 'Oct', monthvalue: 10,
  },
  {
    newdata: '0', publisheddata: '0', year: previousFYyear.toString(), month: 'Nov', monthvalue: 11,
  },
  {
    newdata: '0', publisheddata: '0', year: previousFYyear.toString(), month: 'Dec', monthvalue: 12,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'Jan', monthvalue: 1,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'Feb', monthvalue: 2,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'Mar', monthvalue: 3,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'Apr', monthvalue: 4,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'May', monthvalue: 5,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'Jun', monthvalue: 6,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'Jul', monthvalue: 7,
  },
  {
    newdata: '0', publisheddata: '0', year: currentFYyear.toString(), month: 'Aug', monthvalue: 8,
  },
];

export {
  dummyDatainGraph,
  initialDatainGraph,
};
