/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useState } from 'react';
import Modal, {
  ModalHeader, Theatre,
} from '@ingka/modal';
import '@ingka/modal/dist/style.css';
import './ConnectedProductsModal.scss';
import Carousel from "@ingka/carousel";
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import SSRIcon from '@ingka/ssr-icon';
import imagestrikethrough from "@ingka/ssr-icon/paths/image-strikethrough";
import '@ingka/focus/style.scss';
import SitsContext from '../../../../context/SitsContext';
import fallbackImage from './image-strikethrough.svg';

const ConnectedProductsModal = () => {
  const sitsContext = useContext(SitsContext);
  const [modelOpen, setModelOpen] = useState(true);
  const closeButtonHandler = () => {
    setModelOpen(false);
    sitsContext.updateIsConnectImageClicked('isImageClicked', false);
  };
  return (
    <div id="modal-carousel-banner-connected-info">
      <Modal className="error-modal" handleCloseBtn={closeButtonHandler} visible={modelOpen} focusLockProps={{ disabled: true }}>
        <Theatre
          aria-label="Accessibility header for a modal"
          header={<ModalHeader ariaCloseTxt="Close button text" />}
          className="theatre_carousel"
        >
          <Carousel
            id="default-carousel"
            className="carousel_modal"
          >
            {(sitsContext.connectedProdData.itemsConnected !== null || sitsContext.connectedProdData.itemsConnected.length !== 0)
              && (sitsContext.connectedProdData.itemsConnected.map((imgData, index) => (
                (index === sitsContext.isConnectImageClicked.imageIndex) && (
                  imgData.bigPicURL.length !== 0
                    ? (imgData.bigPicURL.map((bigImgData, index2) => (
                      <div className="connected_information_child_modal">
                        {(bigImgData !== null) && (
                        <img
                          id={`connectedImage${index2}`}
                          src={bigImgData}
                          alt={`connectedProdImage${index2}`}
                          className="connected_info_img_modal"
                          onError={({ currentTarget }) => {
                            currentTarget.classList.add('fallback_image_modal');
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = fallbackImage;
                          }}
                        />
                        )}
                        {(bigImgData === null || (bigImgData === " ")) && (
                        <SSRIcon
                          className="connected_info_no_img_modal"
                          paths={imagestrikethrough}
                        />
                        )}
                      </div>
                    ))) : (
                      <SSRIcon
                        className="connected_info_no_img_modal"
                        paths={imagestrikethrough}
                      />
                    ))
              )))}
          </Carousel>
        </Theatre>
      </Modal>
    </div>
  );
};

export default ConnectedProductsModal;
