/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ConnectedProducts.scss';
import SSRIcon from '@ingka/ssr-icon';
import linkout from "@ingka/ssr-icon/paths/link-out";
import Carousel from "@ingka/carousel";
import ProductIdentifier from "@ingka/product-identifier";
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '../../../../App.scss';
// import '@ingka/carousel/style.scss';
// import '@ingka/tabs/style.scss';
import '@ingka/focus/style.scss';
import information from "@ingka/ssr-icon/paths/information-circle";
import Skeleton from '@ingka/skeleton';
import '@ingka/skeleton/style.scss';
import imagestrikethrough from "@ingka/ssr-icon/paths/image-strikethrough";
import SitsContext from '../../../../context/SitsContext';
import negativeFlowMsg from '../../../../messages/negativeFlowMessages.json';
// eslint-disable-next-line no-unused-vars
import fallbackImage from './image-strikethrough.svg';

const ConnectedProducts = (props) => {
  const sitsContext = useContext(SitsContext);
  // eslint-disable-next-line no-unused-vars
  const [carouselContainerHeight, setCarouselContainerHeight] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [carouselContainerWidth, setCarouselContainerWidth] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [numberOfSkeletonLoading, setNumberOfSkeletonLoading] = useState(0);
  const [changeOfWidth, setChangeOfWidth] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [skeletonLoadingCount, setSkeletonLoadingCount] = useState([1, 2]);

  useEffect(() => {
    if (sitsContext.editorBottomRightWidth !== 0) {
      const calculateSkeletonLoading = Math.round((sitsContext.editorBottomRightWidth - 5) / 110);
      setNumberOfSkeletonLoading(calculateSkeletonLoading);
    }
  }, []);

  useEffect(() => {
    if (sitsContext.connectedProdData.itemsConnected.length !== 0) {
      const editorBottomRightHeaderSectionElement = document.getElementById('editorBottomRightHeaderSection');
      const containerCarouselElement = document.getElementById('editedConnectedCarousel');
      const calcHeight = (sitsContext.editorBottomSectionHeight)
    - (editorBottomRightHeaderSectionElement.offsetHeight);
      setCarouselContainerHeight(calcHeight - 30);
      if ((containerCarouselElement !== null)
      && (containerCarouselElement !== undefined)) {
        containerCarouselElement.style.height = `${calcHeight - 15}px`;
      }
    }
  }, [sitsContext.editorBottomSectionHeight]);

  useEffect(() => {
    if (sitsContext.editorBottomRightWidth !== 0) {
      const calculatedSkeletonCount = Math.floor((sitsContext.editorBottomRightWidth - 37) / 170);
      let remainingSkeletonNeeded = 0;
      let removeLoaderFlag = false;
      setNumberOfSkeletonLoading(calculatedSkeletonCount);
      setCarouselContainerWidth(sitsContext.editorBottomRightWidth);
      const availableWidth = sitsContext.editorBottomRightWidth - 69; // 69 comes from ignoring margin-right of 2 32px
      // and center border width = 5px;
      const totalNumberOfSkeletonNeeded = availableWidth / 191; // 191 is each skeleton loading component width
      if (totalNumberOfSkeletonNeeded > skeletonLoadingCount.length) {
        remainingSkeletonNeeded = Math.abs(totalNumberOfSkeletonNeeded - (skeletonLoadingCount.length));
      } else {
        removeLoaderFlag = true;
        remainingSkeletonNeeded = skeletonLoadingCount.length - totalNumberOfSkeletonNeeded;
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= remainingSkeletonNeeded; i++) {
        if (removeLoaderFlag === false) {
          setSkeletonLoadingCount([...skeletonLoadingCount, i]);
        } else {
          setSkeletonLoadingCount(skeletonLoadingCount.slice(0, -1));
        }
      }
    }
  }, [sitsContext.editorBottomRightWidth]);

  useEffect(() => {
    if (changeOfWidth === true) {
      setChangeOfWidth(false);
    }
  }, [changeOfWidth]);

  const imageOnClickHandler = (index) => {
    const connectedData = {
      isImageClicked: true,
      imageIndex: index,
    };
    sitsContext.updateIsConnectImageClicked('multiplePropertyChange', connectedData);
  };

  const openLinkHandler = (urlData) => {
    window.open(urlData, '_blank');
  };

  return (
    <div
      className={
        `editor_right_container ${(sitsContext.editorHorizontalDragData.resizeFlag === true
      || sitsContext.editorResizeObj.resizeFlag === true) ? 'editor_right_container_userselection' : ''}`
      }
    >
      {(props.selectedSegment.textRowChanged === true)
        && (
        <div id="editedConnectedCarousel" className="editor_right_container_carousel">
          <Carousel
            id="default-carousel"
            className="connected_information_parent"
            hasNoScrollbar={false}
            style={{ width: carouselContainerWidth }}
          >
            {(skeletonLoadingCount !== null || skeletonLoadingCount.length !== 0)
          && (skeletonLoadingCount.map((data, index) => (
            <div key={index} className="connected_information_child">
              <div className="connected_information_child_image">
                <Skeleton style={{ width: '85%' }} className="connected_info_img" />
              </div>
              <div className="connected_information_child_prodname">
                <div className="connected_information_child_name">
                  <Skeleton style={{ width: '100%', height: '100%' }} className="skeleton_child" />
                </div>
                <div className="connected_information_child_category">
                  <Skeleton style={{ width: '100%', height: '100%' }} className="skeleton_child" />
                </div>
              </div>
              <div className="connected_information_child_itemnumber">
                <div>
                  <Skeleton style={{ width: '90%', height: '100%' }} className="skeleton_child" />
                </div>
              </div>
            </div>
          )))}
          </Carousel>
        </div>
        )}
      {((props.selectedSegment.textRowChanged === false)
      && sitsContext.connectedProdData.itemsConnected.length === 0) && (
        <div className="no_results_bottomtab">
          <div>
            {' '}
            <SSRIcon className="information_circle_color" paths={information} />
          </div>
          <div className="no_results_text_styling">{negativeFlowMsg.noConnectedProducts}</div>
        </div>
      )}
      {((props.selectedSegment.textRowChanged === false)
      && sitsContext.connectedProdData.itemsConnected.length !== 0)
      && (
      <div id="editedConnectedCarousel" className="editor_right_container_carousel">
        <Carousel
          id="default-carousel"
          className="connected_information_parent"
          hasNoScrollbar={false}
          style={{ width: carouselContainerWidth }}
        >
          {(sitsContext.connectedProdData.itemsConnected !== null || sitsContext.connectedProdData.itemsConnected.length !== 0)
          && (sitsContext.connectedProdData.itemsConnected.map((data, index) => (
            <div key={index} className="connected_information_child">
              <div className="connected_information_child_image">
                { data.smallPicURL !== null
                && (
                <img
                  src={data.smallPicURL}
                  alt="Connected Products"
                  className="connected_info_img"
                  onClick={() => imageOnClickHandler(index)}
                  onError={({ currentTarget }) => {
                    currentTarget.classList.add('fallback_image_connected_prod');
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = fallbackImage;
                  }}
                />
                )}
                { data.smallPicURL === null
                && (
                  <SSRIcon
                    className="connected_information_no_image"
                    paths={imagestrikethrough}
                    onClick={() => imageOnClickHandler(index)}
                  />
                )}
              </div>
              <div className="connected_information_child_prodname">
                <div className="connected_information_child_name">{data.productName}</div>
                <div className="connected_information_child_category">{data.productType}</div>
              </div>
              <div className="connected_information_child_itemnumber">
                <div>
                  {/* <span className="connected_information_child_item">{data.itemNumber}</span> */}
                  <ProductIdentifier className="connected_information_child_item" value={data.itemNumber} />
                  <span className="connected_information_child_icon"><Link onClick={() => openLinkHandler(data.linkToPiaFacts)}><SSRIcon paths={linkout} className="icon_incorrect" /></Link></span>
                </div>
              </div>
            </div>
          )))}
        </Carousel>
      </div>
      )}
    </div>
  );
};

export default ConnectedProducts;
