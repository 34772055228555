/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useState } from 'react';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/modal/dist/style.css';
import Modal, {
  Sheets, ModalBody, ModalHeader, ModalFooter,
} from '@ingka/modal';
import FormField from '@ingka/form-field';
import TextArea from '@ingka/text-area';
import Button from '@ingka/button';
import './ValidationPopup.scss';
// import { useCookies } from 'react-cookie';
import SitsContext from '../../context/SitsContext';

export default function ValidationPopup(props) {
  // const [langvalue] = useCookies(['selected_locale_Name']);
  const [modalOpen, setModalOpen] = useState(true);
  const sitsContext = useContext(SitsContext);

  const closeModalHandler = () => {
    if (modalOpen === true) {
      setModalOpen(false);
      // Restore button handler
      if (sitsContext.historyRestoreButtonClicked.restoreFlag === true) {
        const restoreDataLocal = {
          versionName: null,
          restoreFlag: false,
        };
        sitsContext.updateHistoryRestoreButtonClicked(restoreDataLocal);
      }

      // Paragraph mismatch handler
      if (sitsContext.paragraphMismatchErrorEditor.openParagraphMismatchErrorPopup === true) {
        const paragraphMismatchFlag = {
          openParagraphMismatchErrorPopup: false,
        };
        sitsContext.updateParagraphMismatchErrorEditor(paragraphMismatchFlag);
      } else if (sitsContext.textPartEmptyErrorEditor.openTextPartEmptyErrorPopup === true) {
        // Text part empty handler
        const textPartErrorPopupFlag = {
          openTextPartEmptyErrorPopup: false,
        };
        sitsContext.updateTextpartEmptyErrorEditor(textPartErrorPopupFlag);
      }

      // Number of items affected handler
      if (sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup === true) {
        const numberOfItemsAffectedLocalData = {
          openNumberOfItemsAffectedPopup: false,
          numberOfItemsAffectedFlag: false,
          isSegmentsInfoThere: null,
          isRevertChangesLoading: false,
        };
        sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
      }

      // Duplicate Translation Handler
      if (sitsContext.duplicateTranslation.openDuplicateTranslationPopup === true) {
        const duplicateTranslationLocalData = {
          openDuplicateTranslationPopup: false,
        };
        sitsContext.updateNumberOfItemsAffected(duplicateTranslationLocalData);
      }

      // Segments missing handler
      if (sitsContext.segmentsMissingValidation.openSegmentsMissingValidationErrorPopup === true) {
        const localOpenSegmentsMissingData = {
          openSegmentsMissingValidationErrorPopup: false,
        };
        sitsContext.updateSegmentsMissingValidationEditor(localOpenSegmentsMissingData);
      }
    }
  };

  const restoreOnModalOnClickHandler = () => {
    props.historyRestoreFunc();
  };

  const revertChangesHandler = () => {
    if (props.validationData.primaryButton.toLowerCase() === 'go back to translate') {
      const numberOfItemsAffectedLocalData = {
        openNumberOfItemsAffectedPopup: false,
        numberOfItemsAffectedFlag: false,
        isSegmentsInfoThere: null,
        isRevertChangesLoading: false,
      };
      sitsContext.updateNumberOfItemsAffected(numberOfItemsAffectedLocalData);
    } else if (props.validationData.primaryButton.toLowerCase() === 'revert changes') {
      props.revertChanges();
    }
  };

  const confirmButtonSaveHandler = () => {
    props.confirmSaveHandler();
  };

  return (
    <div>
      <Modal
        escapable
        style={{
          display:
            props.validationMessage === true && modalOpen === true
              ? ''
              : 'none',
        }}
        focusLockProps={{ disabled: false }}
        visible
        handleCloseBtn={closeModalHandler}
      >
        <Sheets
          header={
            props.validationMessage === true && (
              <ModalHeader title={props.validationData.validationHeader} />
            )
          }
          footer={(
            <ModalFooter className="modal_footer_button">
              <React.Fragment key=".0">
                {(sitsContext.historyRestoreButtonClicked.restoreFlag !== true
                && sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup !== true)
              && (
              <Button
                text="Go back to translate"
                type="primary"
                id="primaryButton"
                onClick={closeModalHandler}
              />
              )}
                {
                (sitsContext.segmentsMissingValidation.openSegmentsMissingValidationErrorPopup === true)
                && (
                  <Button
                    text="Confirm"
                    type="secondary"
                    id="secondaryButton"
                    onClick={confirmButtonSaveHandler}
                  />
                )
                }
                {sitsContext.numberOfItemsAffected.openNumberOfItemsAffectedPopup === true
              && (
              <>
                <Button
                  text={props.validationData.primaryButton}
                  type="primary"
                  id="primaryButton"
                  onClick={revertChangesHandler}
                  loading={sitsContext.numberOfItemsAffected.isRevertChangesLoading}
                />
                <Button
                  text="Continue"
                  type="secondary"
                  id="secondaryButton"
                  onClick={confirmButtonSaveHandler}
                />
              </>
              )}
                {sitsContext.historyRestoreButtonClicked.restoreFlag === true
              && (
              <>
                <Button
                  text="Cancel"
                  type="primary"
                  id="primaryButton"
                  onClick={closeModalHandler}
                />
                <Button
                  text="Restore"
                  type="secondary"
                  id="secondaryButton"
                  onClick={restoreOnModalOnClickHandler}
                  loading={sitsContext.historyRestoreButtonClicked.isRestoreButtonLoading}
                />
              </>
              )}
              </React.Fragment>
            </ModalFooter>
          )}
        >
          <ModalBody>
            <div className="componentPadding" dangerouslySetInnerHTML={{ __html: props.validationData.validationMessage }} />
            {(sitsContext.textPartEmptyErrorEditor.textPartEmptyErrorFlag === true
              && sitsContext.textPartEmptyErrorEditor.officialLanguage !== ''
            )
            && (
              <FormField>
                <TextArea
                  className="componentPadding"
                  id="officialEnglish"
                  defaultValue={sitsContext.textPartEmptyErrorEditor.officialLanguage}
                  label="Official English"
                  readOnly
                />
              </FormField>
            )}
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
  );
}
