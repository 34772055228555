export default function dateFilterData() {
  const today = new Date().toISOString().split('T')[0];

  // const currentYear = new Date().getFullYear(); // current Year
  const yesterday = () => {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(new Date().getDate() - 1);
    const date = yesterdayDate.toISOString().split('T')[0];
    return date;
  };

  const currentWeek = () => {
    const daystoMonday = (new Date().getDay() + 6) % 7;

    const startOfWeek = new Date(new Date());
    startOfWeek.setDate(new Date().getDate() - daystoMonday);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return {
      startDate: startOfWeek.toISOString().split('T')[0],
      endDate: endOfWeek.toISOString().split('T')[0],
    };
  };
  // startDate & endDate of the CurrentWeek

  const previousWeek = () => {
    const daystoMonday = (new Date().getDay() + 6) % 7; // Calc days to monday

    const startOfWeek = new Date(new Date());
    startOfWeek.setDate(new Date().getDate() - daystoMonday - 7);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return {
      startDate: startOfWeek.toISOString().split('T')[0],
      endDate: endOfWeek.toISOString().split('T')[0],
    };
  };
  // startDate & endDate of the PreviousWeek

  const currentMonth = () => {
    const startdate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1));
    const enddate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    return {
      startDate: startdate.toISOString().split('T')[0],
      endDate: enddate.toISOString().split('T')[0],
    };
  };
  // startDate & endDate of the currentMonth

  const todayDate = today;
  const yesterdayDate = yesterday();
  const presentWeekDate = currentWeek();
  const previousWeekDate = previousWeek();
  const presentMonthDate = currentMonth();

  const dateFiltervalues = [
    {
      dateFilter_name: 'Today',
      value: [
        {
          fromDate: todayDate,
          toDate: todayDate,
        },
      ],
    },
    {
      dateFilter_name: 'Yesterday',
      value: [
        {
          fromDate: yesterdayDate,
          toDate: yesterdayDate,
        },
      ],
    },
    {
      dateFilter_name: 'This week',
      value: [
        {
          fromDate: presentWeekDate.startDate,
          toDate: presentWeekDate.endDate,
        },
      ],
    },
    {
      dateFilter_name: 'Last week',
      value: [
        {
          fromDate: previousWeekDate.startDate,
          toDate: previousWeekDate.endDate,
        },
      ],
    },
    {
      dateFilter_name: 'This month',
      value: [
        {
          fromDate: presentMonthDate.startDate,
          toDate: presentMonthDate.endDate,
        },
      ],
    },
  ];
  return dateFiltervalues;
}
