/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/function-component-definition */
/* eslint-disable comma-dangle */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-shadow */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState, useEffect, useContext, useLayoutEffect,
} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Table, { TableHeader, TableBody } from '@ingka/table';
import '@ingka/table/style.scss';
import edit from '@ingka/ssr-icon/paths/pencil';
import './Resultscomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/tag/style.scss';
import '../../../App.scss';
import Button from '@ingka/button';
// import Tag from '@ingka/tag';
import CommercialMessage from '@ingka/commercial-message';
import enabledObjects from '../../../enabledObjects/enableObjects.json';
import { selectedPopupValue } from '../../../App';
import { selectedPage } from '../../../common-components/header/Header';
import SitsContext from '../../../context/SitsContext';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';

export default function Resultscomponent(props) {
  const navigate = useNavigate();
  const sitsContext = useContext(SitsContext);
  const [pagenum, setPagenum] = useState(1); // intializing pagenum to capture the page number
  const [searchresults, setSearchresults] = useState([]);
  // intializing searchresults array to capture the searched results
  const [rtl_lang, setRtl_lang] = useState(false);
  // const resultsRef = useRef();

  let searial = ''; // To capture the serial number in results table
  let pagenumber = 1; // intializing the pagenumber with 1 for the intial state

  // functionality to check the rtl_language when there is a change in language code
  useEffect(() => {
    const selectedlang_code = selectedPopupValue.selectedlang;
    if (selectedlang_code === 'AR' || selectedlang_code === 'HE') {
      setRtl_lang(true);
    } else { setRtl_lang(false); }
  }, [selectedPopupValue.selectedlang]);

  useEffect(() => {
    if (searchresults.length !== 0) {
      sitsContext.updatePreviousData(
        searchresults,
        0,
        props.answer.source_locale,
        props.answer.target_locale,
      );
    }
  }, [searchresults]);

  useLayoutEffect(() => {
    const resultsElement = document.getElementById('Resultstable');
    if ((sitsContext.previousStateScrollSearchResults !== 0)
      && (resultsElement !== undefined)) {
      setTimeout(() => {
        resultsElement.scrollTo(0, sitsContext.previousStateScrollSearchResults);
      }, 100);
    }
    // if (
    //   sitsContext.pageRedirection
    //   && (sitsContext.scrollOffset !== null) && (sitsContext.scrollOffset !== undefined)
    //   && (sitsContext.scrollOffset !== 0)) {
    //   setTimeout(() => {
    //     const el = document.getElementById(sitsContext.scrollOffset);
    //     if ((el === undefined) || (el === null)) {
    //       resultsElement.scrollTo(0, sitsContext.previousStateScrollSearchResults);
    //     } else {
    //       // el.scrollIntoView();
    //       resultsElement.scrollTo(0, sitsContext.previousStateScrollSearchResults);
    //     }
    //   }, 100);
    // } else if (sitsContext.pageRedirection && (sitsContext.scrollOffset === 0)) {
    //   setTimeout(() => {
    //     resultsElement.scrollTo(0, sitsContext.previousStateScrollSearchResults);
    //   }, 100);
    // }
  }, []);

  useLayoutEffect(() => {
    if (sitsContext.pageRedirection) {
      setTimeout(() => {
        setPagenum(sitsContext.previousStatePageNumber);
      }, 100);
    }
  }, []);

  // functionality to change the height of the results table when there
  // is a change in display filters component
  useEffect(() => {
    const el = document.getElementById('Resultstable');
    if (props.height === 0) { el.style.height = `calc(100vh - ${183 + props.bannerheight}px)`; }// if the height of diplay filters is 0
    if (props.height > 0) {
      el.style.height = `calc(100vh - ${183 + props.height + props.bannerheight}px)`;
    } // if the height of diplay filters is >0
  }, [props.height, props.bannerheight]);
  // functionality to concatnate the previous results when there
  // is change in the searched results datalist
  useEffect(() => {
    if (props.answer !== null) {
      if (props.answer.data !== undefined
      && props.answer.data.length !== 0 && props.answer.data.length !== undefined
        && props.answer.data.length !== null) {
        if (props.answer.data[0].serial_no === 1) {
          setPagenum(1);
          const el = document.getElementById('Resultstable');
          el.scrollTop = 0;
          setSearchresults(props.answer.data);
        // when the serial number starts with "1" previous results are updates with the new results
        } else {
          setSearchresults((searchresults) => [...searchresults.concat(props.answer.data)]);
          // The previous results is concatinated with the new results
        }
      }
    }
  }, [props.answer]);
  // The page number will be incremented based upon the scrolling
  // page num value is passed as props
  const onscroll = (e) => {
    sitsContext.updatePreviousScrollSearchResults(e.target.scrollTop);
    if ((props.answer.data[props.answer.data.length - 1].serial_no) !== props.count) {
      const pageheight = Math.ceil(e.target.scrollHeight - e.target.scrollTop);
      const scroll = e.target.clientHeight;
      if (pageheight === scroll || pageheight === scroll + 1
        || pageheight === scroll + 2 || pageheight === scroll - 1 || pageheight === scroll - 2) {
        pagenumber = pagenum + 1;
        setPagenum(pagenumber);
        sitsContext.updatePreviousPageNumber(pagenumber);
        props.PageNumber(pagenumber);
      } // the updated page number is passed to other components using props method
    }
  };

  const onRedirectToEditPageHandler = (
    textId,
    page,
    selectedtext,
    source_Locale,
    target_Locale,
    statusofSelectedid
  ) => {
    if (sitsContext.autoSaveHeaderChange !== null) {
      sitsContext.updateAutoSaveHeaderChange(null);
    }
    sessionStorage.setItem('funcCalled', 'true');
    const selectedTextData = [{
      textid: selectedtext.text_id,
      source_locale_code: 'en-ZZ',
      target_locale_code: selectedPopupValue.selectedlangvalue,
      object_type_short: selectedtext.object_name_short,
      isParentEnabled: true,
    }];

    const listOfIdsSelected = {
      dataOfIds: {
        textId: selectedtext.text_id,
        objectNameShort: selectedtext.object_name_short,
      },
    };
    sitsContext.updateDataOfRelaseTexts([listOfIdsSelected.dataOfIds], statusofSelectedid.toLowerCase() === 'translated' ? [selectedtext.text_id] : []);
    // console.log([listOfIdsSelected.dataOfIds], statusofSelectedid === 'translated' ? 1 : 0);
    // eslint-disable-next-line max-len
    sitsContext.updateEditorSearchPageLocationData(page, selectedTextData, source_Locale, target_Locale);
    sitsContext.updateFilterDisplayFlag(false);
    sitsContext.updateInitialFilterFlag(false);
    sitsContext.updatePreviousData(
      searchresults,
      textId,
      props.answer.source_locale,
      props.answer.target_locale,
    );
    sitsContext.updatePageRedirection(true);
    sitsContext.updateNavigateEditToSearchPageFlag(`${selectedPage}`);
  };

  // eslint-disable-next-line max-len
  const redirecttoeditor = (textId, page, selectedtext, source_Locale, target_Locale, statusofid) => {
    // eslint-disable-next-line max-len
    // sitsContext.updateEditorSearchPageLocationData(page, selectedtext, source_Locale, target_Locale);
    sessionStorage.setItem('funcCalled', 'true');
    if (enabledObjects.objectsEnabledforEdit.includes(selectedtext.object_type)) {
      navigate('/Editorpage', {
        state: {
          selectedPageTitle: page,
          selectedTextData: selectedtext,
          sourceLocale: source_Locale,
          targetLocale: target_Locale
        }
      });
      // eslint-disable-next-line max-len
      onRedirectToEditPageHandler(selectedtext.text_id, page, selectedtext, source_Locale, target_Locale, statusofid);
    }
  };

  return (
    /* eslint-disable */
    <div>
     {/* <ScrollRestoration> */}
      <div>
       <div id="Resultstable" onScroll={onscroll} className="tablescroll"> 
        <Table style={{ width: ' 100% ' }}>
          <TableHeader className="table_header" sticky>
            <tr>
              <th className="heading table_content_padding"> </th>
              <th className="heading table_content_padding">{props.answer.source_locale}</th>
              <th className={rtl_lang ? 'heading rtl_lang table_content_padding' : 'heading table_content_padding'}>{props.answer.target_locale}</th>
              <th className="heading table_content_padding">Type</th>
              <th className="heading table_content_padding">Object</th>
              <th className="heading table_content_padding">Status</th>
              <th className="heading table_content_padding"> </th>
            </tr>
          </TableHeader>
          {searchresults.map((text, index) => (
            <TableBody key={index}>
              <tr><td style={{ display: 'none' }}>{searial = text.results.length}</td></tr>
              {text.results.map((textdata, rowindex) => (
                <tr className='searchPage_table_row' key={rowindex} id={textdata.text_id}>
                  <td className="textalign serial_no table_content_padding table_cell_border width3"
                    rowSpan={searial} style={{ display: rowindex === 0 ? 'null' : 'none' }}
                  >
                    {rowindex === 0 ? text.serial_no : 'null'}
                  </td>
                  <td
                    className="width textalign output_text table_content_padding table_cell_border
                    white_space"
                    dangerouslySetInnerHTML={{ __html: textdata.source_text}}
                  />
                  <td
                   className={
                    rtl_lang === true
                   ? `width textalign output_text rtl_lang table_content_padding table_cell_border white_space
                   ${enabledObjects.objectsEnabledforEdit.includes(textdata.object_type) ? 'target_onhover_pointer' : ''}`
                   : `width textalign output_text table_content_padding table_cell_border white_space
                   ${enabledObjects.objectsEnabledforEdit.includes(textdata.object_type)
                   ? 'target_onhover_pointer' : ''}`
                   }
                   onClick={() => { redirecttoeditor(textdata.text_id , selectedPage , textdata ,
                   props.answer.source_locale , props.answer.target_locale, textdata.status)}}
                   >
                   <span dangerouslySetInnerHTML={{ __html: textdata.target_text }}></span>
                  </td>
                  <td className="width2 textalign table_content_padding table_cell_border">
                    {textdata.part_name}
                  </td>
                  {rowindex === 0
                  && <td className="width1 textalign table_content_padding table_cell_border" 
                       rowSpan={text.results.length}
                       style={{ display: rowindex === 0 ? 'null' : 'none' }}
                     >
                       {textdata.object_type}
                     </td>
                  }
                  {rowindex === 0
                  && <td
                      className="width textalign output_text table_content_padding table_cell_border
                      no_wrap_text"
                      rowSpan={text.results.length}
                      style={{ display: rowindex === 0 ? 'null' : 'none' }}>
                        <div>
                          <span className="status_plain_text">
                            {textdata.status.charAt(0).toUpperCase() + textdata.status.slice(1)}
                          </span>
                        </div>
                        {textdata.tag === "overdue"
                          && <div className="tag_spacing">
                              <CommercialMessage variant="nlp" message="Overdue" />
                             </div>
                        }
                     </td>
                  }
                  {rowindex === 0
                  && enabledObjects.objectsEnabledforEdit.includes(textdata.object_type)
                  && <td className="width2 table_content_padding table_cell_border table_edit_icon"
                      rowSpan={text.results.length}>
                        <Link className={enabledObjects.objectsEnabledforEdit.includes(textdata.object_type)
                          ? 'editor_enabled' : 'editor_link'}
                          onClick={(event) => {onRedirectToEditPageHandler(
                            textdata.text_id,
                            selectedPage ,
                            textdata ,
                            props.answer.source_locale ,
                            props.answer.target_locale , 
                            textdata.status
                            ) }}
                          state={{
                            selectedPageTitle : selectedPage,
                            selectedTextData: textdata,
                            sourceLocale: props.answer.source_locale,
                            targetLocale: props.answer.target_locale
                          }}
                          to="/Editorpage"
                        >
                          <Button type="tertiary"
                            small
                            disabled={enabledObjects.objectsEnabledforEdit.includes(textdata.object_type) ? false : true}
                            iconOnly
                            ssrIcon={edit}
                            text={lablesAndnames.searchPage_editButtonText}
                          />
                        </Link>
                     </td>}
                  {rowindex === 0
                   &&
                   !enabledObjects.objectsEnabledforEdit.includes(textdata.object_type)
                    &&
                    <td className="width2 table_content_padding table_cell_border"
                      rowSpan={text.results.length}>
                        <Button type="tertiary"
                          small
                          disabled={enabledObjects.objectsEnabledforEdit.includes(textdata.object_type) ? false : true}
                          iconOnly
                          ssrIcon={edit}
                          text={lablesAndnames.searchPage_editButtonText}
                        />
                    </td>
                  }
              </tr>))}
              <tr className="spacing" />
            </TableBody>
          ))}
          </Table>   
       </div>  
      </div>
      {/* </ScrollRestoration>   */}
      {' '}
    </div>
  );
};
