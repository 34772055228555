/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
export const currentMonthNumber = new Date().getMonth() + 1;
// Months are started from 0 so add 1 for exact month
export const filtersarray = [];
// array to store the selected filters in each of the dropdown
export const itemNumberFormat = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{2})$/;
// item number Format
export const itemNumber = /^([0-9]{10})?$/;
// item number check
export const currentFYyear = (new Date().getMonth().toString()) > 7
  ? ((new Date().getFullYear()) + 1) : (new Date().getFullYear());
// to get the current financial year
// 7 indicates august month (0 to 11) as financial year starts from september
export const numberOfFinancialYears = 1;
// number of finanancial years to select
export const previousFYyear = (currentFYyear) - 1;
// For the previous finanacial year
export const sourceLocaleName = 'Official English';
// source language
export const notReleasedStatus = 'Not released';
// for updating the text as not released
export const spaceAfterhundreds = (originalNumber) => {
  const spacednumber = originalNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return spacednumber;
};
// function to induce space after every three number
export const expandingInformationInPill = (textData, characterCountLimit) => {
  const words = textData.split(' ');
  const paragraphs = [];
  let currentWords = [];

  words.forEach((word) => {
    const currentLength = currentWords.join(' ').length;

    // If adding the next word exceeds the limit, push the current paragraph
    if (currentLength + word.length + 1 > characterCountLimit) {
      paragraphs.push(currentWords.join(' '));
      currentWords = []; // Reset for the next paragraph
    }

    currentWords.push(word); // Add the word to the current paragraph
  });

  // Push the last paragraph
  if (currentWords.length > 0) {
    paragraphs.push(currentWords.join(' '));
  }

  return (
    paragraphs
  );
};
