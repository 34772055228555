/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Graphcomponent from '../components/startpage/graphcomponent/Graphcomponent';
import Pendingtranslations from '../components/startpage/pendingtranslations/Pendingtranslations';
import '../App.scss';
import SSRIcon from '@ingka/ssr-icon';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import information from '@ingka/ssr-icon/paths/information-circle';
import checkMark from '@ingka/ssr-icon/paths/checkmark-circle';
import negativeFlowMsg from '../messages/negativeFlowMessages.json';
import Dueindatatable from '../components/startpage/dueinData/Dueindatatable';
import Filtercomponent from '../components/searchpage/filters/Filtercomponent';
import Diplayfilters from '../components/searchpage/filters/Diplayfilters';
import Graphtabs from '../components/startpage/graphcomponent/Graphtabs';
import { selectedPopupValue } from '../App';
import { retrievedueindata, retrievedueindataCount, retrieveDueinDataCountForFilter } from '../api/StartpageApirequests';
import Loadingcomponent from '../common-components/loader/Loadingcomponent';
import ToastMessage from '../common-components/toastMessage/ToastMessage';
import SitsContext from '../context/SitsContext';
import { useCookies } from 'react-cookie';
import BulkEdit from '../common-components/bulkEdit/BulkEdit';
import ErrorHandler from '../common-components/errorhandler/ErrorHandler';

let filtersLength = '0';
let localDueInData = [];
// eslint-disable-next-line import/no-mutable-exports
export let correlationid = [];

export default function Dashboardpage(props) {
  let overdueFilter = 'Overdue';
  const navigate = useNavigate();
  const sitsContext = useContext(SitsContext);
  const location = useLocation();
  const [editPageErrorhandler, setEditPageErrorhandler] = useState(false);
  const [correlationId, setCorrelationId] = useState(1);
  const [correlationIdForCount, setCorrelationIdForCount] = useState(1);
  const [countError, setCountError] = useState(false);
  const [graphtabselected, setGraphtabselected] = useState();
  const [selectedfilters, setSelectedfilters] = useState([]);
  //  intializing an array for selected filters
  const [selectedfiltersvalue, setSelectedfiltersvalue] = useState([]);
  //  intializing an array for selected filters name
  const [cleared, setCleared] = useState();
  // intializing the cleared to capture the cleared filters value
  const [clearedvalues, setClearedvalues] = useState();
  // intialing the clearedvalues to capture the cleared filters name
  const [selectedfiltersforapi, setSelectedfiltersforapi] = useState([]);
  const [dueindata, setDueindata] = useState([]);
  const [dueindatacount, setDueindataCount] = useState([]);
  const [enableload, setEnableload] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading your results');
  const [countload, setCountload] = useState(false);
  const [filteritemCount, setFiltersitemCount] = useState([]);
  const [resetFilters, setResetFilters] = useState([false]);
  const [previouslangvalue, setPreviouslangvalue] = useState(null);
  const [langvalue, setlangvalue] = useCookies(['selected_locale_Name']);
  const [selectedFinancialYear, setselectedFinancialYear] = useState();
  const targetLocale = selectedPopupValue.selectedlangvalue;

  //  user selected lang_code from the popup is assigned to target locale.
  // Results table will not be displayed when there is change in target_locale
  useEffect(() => {
    if ((sitsContext.currentTargetLocale !== null
      && sitsContext.currentTargetLocale !== undefined)) {
      if (sitsContext.currentTargetLocale !== targetLocale) {
        // setNodisplay(true);
        // setPagedata(1);
        refreshStartPage();
        sitsContext.updateCurrentTargetLocale(targetLocale);
        sitsContext.updateTabNavigation('tab_0');
        sitsContext.updateEnableSearchbuttoninfilter(false);
        sitsContext.updateintialfiltervalue(true);
        if (sitsContext.checkInitState) {
          sitsContext.updateFilterDisplayFlag(false);
          sitsContext.updateInitialFilterFlag(false);
          sitsContext.updatePageRedirection(true);
        }
        sitsContext.updateCurrentStatePageName('dashboard');
        sitsContext.resetSearchPageData();
        navigate('/Dashboardpage', { state: { path: 'dashboardpage', localeChange: true } });
        sitsContext.updateToastMessage(true, `${langvalue.selected_locale_Name} loaded successfully!`);
        window.history.replaceState({}, document.title);
      }
    } else {
      sitsContext.updateCurrentTargetLocale(targetLocale);
    }
  }, [targetLocale]);

  useEffect(() => {
    if ((location.state !== null)
    && (location.state.localeChange !== undefined)
    && (location.state.localeChange === true)) {
      sitsContext.updateToastMessage(true, `${langvalue.selected_locale_Name} loaded successfully!`);
      window.history.replaceState({}, document.title);
    }
  }, []);

  useEffect(() => {
    if (sitsContext.startpageDueinData.length !== 0
      && sitsContext.editorPageStatusButton === 'loadPreviousData' && sitsContext.startpageDueinDataloaded === true) {
      sitsContext.updateMatchesSuggestionsCount(null);
      if (location.state !== null) {
        if (location.state.buttonClicked === 'error' && location.state.buttonClicked !== null) {
          correlationid = location.state.correlationId;
          setEditPageErrorhandler(true);
        }
      }
      setEnableload(true);
      setCountload(true);
      sitsContext.updateintialloading(false);
      setTimeout(() => {
        setDueindata(sitsContext.startpageDueinData);
        localDueInData = [...sitsContext.startpageDueinData];
        setDueindataCount(sitsContext.startpageDueindataCount);
        setFiltersitemCount(sitsContext.startpageDueinFilterCount);
        setCountload(false);
        setEnableload(false);
      }, 10);
    } else if (sitsContext.startpageDueinData.length !== 0 && sitsContext.editorPageStatusButton === 'loadNewData') {
      sitsContext.updateMatchesSuggestionsCount(null);
      filtersLength = '0';
      const selectedfilterstoapi = {
        Objecttypefilters: sitsContext.startPagepreviousStateObjectFilterShortName,
        Statusfilters: sitsContext.startPagepreviousStateStatusFilterShortName,
        Sortfilters: sitsContext.startPagepreviousStateSortFilterShortName,
        dueinfilters: sitsContext.previousStateDueinFilterShortName,
        categoryfilters: sitsContext.previousStateCategoryFilterShortName,
      };
      sitsContext.updateintialloading(false);
      filtersLength = (selectedfilterstoapi.Objecttypefilters.length
        + selectedfilterstoapi.Statusfilters.length
        + (selectedfilterstoapi.Sortfilters === undefined ? 0 : 1)
        + selectedfilterstoapi.dueinfilters.length
        + selectedfilterstoapi.categoryfilters.length);
      dueinDataTrigger(selectedPopupValue.selectedlangvalue, selectedfilterstoapi);
      dueinCountTrigger(selectedPopupValue.selectedlangvalue, selectedfilterstoapi);
      filterItemsCountTrigger(selectedPopupValue.selectedlangvalue, selectedfilterstoapi);
    }
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById('DashboardPage').scrollTo(0, sitsContext.currentStartPageScroll);
    }, 50);
  }, [dueindata]);

  const refreshStartPage = () => {
    setResetFilters('reset'); // filters will be cleared
    setSelectedfilters(['Overdue']); // assigning the filtersname
    setSelectedfiltersvalue(['Overdue']);
  };

  useEffect(() => {
    if (sitsContext.selectedtextsforbulkedit.length === 0) {
      const el = document.getElementById('DashboardPage');
      el.style.height = `calc(100vh - ${52 + props.heightofbanner}px)`;
    } else if (sitsContext.selectedtextsforbulkedit.length !== 0) {
      const el = document.getElementById('DashboardPage');
      el.style.height = `calc(100vh - ${52 + props.heightofbanner + 72}px)`;
    }
  }, [props.heightofbanner, sitsContext.selectedtextsforbulkedit.length, enableload]);

  const dueinDataTrigger = (selectedlangCode, selectedFilters) => {
    sitsContext.updateStartpageDueinData(false, []);
    if (selectedPopupValue.selectedlangvalue !== undefined) {
      setEnableload(true);
      retrievedueindata(selectedlangCode, selectedFilters, filtersLength, tableData);
      sitsContext.updateEditorPageStatusButton('loadPreviousData');
    }
  };

  const dueinCountTrigger = (selectedlangCode, selectedFilters) => {
    if (selectedPopupValue.selectedlangvalue !== undefined) {
      setCountload(true);
      retrievedueindataCount(
        selectedlangCode,
        selectedFilters,
        filtersLength,
        tableDataCount,
      );
    }
  };

  const filterItemsCountTrigger = (selectedlangCode, selectedFilters) => {
    retrieveDueinDataCountForFilter(
      selectedlangCode,
      selectedFilters,
      eachFilterCount,
    );
  };

  // eslint-disable-next-line consistent-return
  const updateCategoryCountThreshold = () => {
    if (window.innerWidth < 853) { // Zoom level = Greater than 150%
      return 1;
    } if (window.innerWidth < 1280 && window.innerWidth > 853) { // Zoom level = Greater than 100% and
      // lesser than 150%
      return 3;
    } if (window.innerWidth === 1280) { // Zoom level = 100%
      return 4;
    } if (window.innerWidth > 1280 && window.innerWidth < 1600) { // Zoom level = Lesser than 100%
      // and greater than 80%
      return 5;
    } if (window.innerWidth > 1600) { // Zoom level = lesser than 80%
      return 6;
    }
  };

  const tableData = (response, apiFiltersLength) => {
    if (response.status === 200) {
      if (filtersLength === apiFiltersLength) {
        const categoryThresholdCount = updateCategoryCountThreshold();
        const updatedDueInData = response.data.map((individualDueInTextDetail) => {
          if (individualDueInTextDetail.categories !== null
              && individualDueInTextDetail.categories.length > categoryThresholdCount
          ) {
            return {
              ...individualDueInTextDetail,
              updatedCategories: individualDueInTextDetail.categories.slice(0, categoryThresholdCount),
              remainingCategories: individualDueInTextDetail.categories.filter((categoryInfo, categoryIndex) => (categoryIndex >= categoryThresholdCount)),
              categoryOverflow: true,
              clickedMore: false,
              moreCategoriesCount: individualDueInTextDetail.categories.length - categoryThresholdCount,
            };
          }
          return {
            ...individualDueInTextDetail,
            categoryOverflow: false,
          };
        });
        setDueindata(updatedDueInData);
        localDueInData = [...updatedDueInData];
        sitsContext.updateStartpageDueinData(true, updatedDueInData);
        setEnableload(false);
        setCorrelationId(1);
        setCountError(false);
      }
    }
    if (response.status !== 200) {
      setCountError(false);
      setCorrelationId(response.data.CorrelationID);
      sitsContext.updateStartpageDueinData(false, []);
      setEnableload(false);
    }
  };

  const tableDataCount = (response, apiFiltersLength) => {
    if (response.status === 200) {
      if (filtersLength === apiFiltersLength) {
        setDueindataCount(response.data);
        sitsContext.updateStartpageDueinDataCount(true, response.data);
        setCountload(false);
        setCountError(false);
        setCorrelationIdForCount(1);
      }
    }
    if (response.status !== 200) {
      setCountError(true);
      setCorrelationIdForCount(response.data.CorrelationID);
      sitsContext.updateStartpageDueinDataCount(false, []);
      setCountload(false);
    }
  };

  const eachFilterCount = (itemscountdata1, itemscountdata2, itemscountdata3, itemscountdata4) => {
    const itemscountdatas = {
      dueinCount: itemscountdata1.dueFilters,
      objecttypeCount: itemscountdata2.objFilters,
      statusCount: itemscountdata3.statusFilters,
      categoryCount: itemscountdata4.categoryFilters,
      // eslint-disable-next-line no-param-reassign
    };
    setFiltersitemCount(itemscountdatas);
    sitsContext.updateStartpageDueinFilterCount(true, itemscountdatas);
  };

  const displayfilter = (Updatedfilters, Updatedfiltersvalue) => {
    setCleared(Updatedfilters); // cleared filters value
    setClearedvalues(Updatedfiltersvalue); // cleared filters name
  };

  const Selectedvalues = (value) => {
    if (selectedPopupValue.selectedlangvalue !== undefined
      && sitsContext.intialfiltervalue !== false) {
      filtersLength = value.selectedfilters.length;
      setResetFilters(false);
      setSelectedfilters(value.selectedfiltersName); // assigning the filtersname
      setSelectedfiltersvalue(value.selectedfilters); // assigning the filtersvalue
      setSelectedfiltersforapi(value.selectedfilterstoapi);
      if (sitsContext.intialloading === true) {
        setEnableload(true);
        dueinDataTrigger(selectedPopupValue.selectedlangvalue, value.selectedfilterstoapi);
        dueinCountTrigger(selectedPopupValue.selectedlangvalue, value.selectedfilterstoapi);
        filterItemsCountTrigger(selectedPopupValue.selectedlangvalue, value.selectedfilterstoapi);
      }
    }
    sitsContext.updateintialfiltervalue(false);
  };
  const heightoffilters = (height) => {
    const varyheight = height;
  };

  const disableErrorModal = () => {
    setEditPageErrorhandler(false);
  };

  const graphtabvalue = (graphvalue) => {
    setGraphtabselected(graphvalue);
  };

  const selectedYear = (financialYearValue) => {
    setselectedFinancialYear(financialYearValue);
  };

  const trackscroll = (e) => {
    sitsContext.updateCurrentStartPageScroll(e.target.scrollTop);
  };

  // eslint-disable-next-line consistent-return
  const compareArraysHandler = (array1, array2) => {
    if (array1.length === array2.length) {
      return array1.every((element, index) => element === array2[index]);
    }
    return false;
  };

  useEffect(() => {
    if (window.innerWidth < 853) { // Zoom level = Greater than 150%
      categoriesResizeHandler(1);
    } else if (window.innerWidth < 1280 && window.innerWidth > 853) { // Zoom level = Greater than 100% and
    // lesser than 150%
      categoriesResizeHandler(3);
    } else if (window.innerWidth === 1280) { // Zoom level = 100%
      categoriesResizeHandler(4);
    } else if (window.innerWidth > 1280 && window.innerWidth < 1600) { // Zoom level = Lesser than 100%
    // and greater than 80%
      categoriesResizeHandler(5);
    } else if (window.innerWidth > 1600) { // Zoom level = lesser than 80%
      categoriesResizeHandler(6);
    }
  }, [props.windowResize]);

  const categoriesResizeHandler = (visibleCategoryCount) => {
    setEnableload(true);
    const updatedDueInData = localDueInData.map((individualDueInTextDetail) => {
      if (individualDueInTextDetail.categories !== null
          && individualDueInTextDetail.categories.length > visibleCategoryCount
      ) {
        return {
          ...individualDueInTextDetail,
          updatedCategories: individualDueInTextDetail.categories.slice(0, visibleCategoryCount),
          remainingCategories: individualDueInTextDetail.categories.filter((categoryInfo, categoryIndex) => (categoryIndex >= visibleCategoryCount)),
          categoryOverflow: true,
          clickedMore: false,
          moreCategoriesCount: individualDueInTextDetail.categories.length - visibleCategoryCount,
        };
      }
      return {
        ...individualDueInTextDetail,
        categoryOverflow: false,
      };
    });
    setDueindata(updatedDueInData);
    setEnableload(false);
  };

  const categoryMoreClickHandler = (textIds) => {
    let doneUpdated = false;
    const updatedDueInData = dueindata.map((individualDueInTextDetail) => {
      if (doneUpdated === false) {
        const areTextIdsEqual = compareArraysHandler(individualDueInTextDetail.textIds, textIds);
        if (areTextIdsEqual) {
          doneUpdated = true;
          return {
            ...individualDueInTextDetail,
            categoryOverflow: false,
          };
        }
      }
      return {
        ...individualDueInTextDetail,
      };
    });
    setDueindata(updatedDueInData);
    localDueInData = [...updatedDueInData];
  };

  return (
    <div id="DashboardPage" className="dashboard_page" onScroll={(e) => trackscroll(e)}>
      <ToastMessage />
      {correlationId !== 1 ? <ErrorHandler errorDisplayed={11} correlationIdForCountLoad={correlationId} /> : ''}
      {correlationIdForCount !== 1 ? <ErrorHandler errorDisplayed={12} correlationIdForCountLoad={correlationIdForCount} /> : ''}
      {editPageErrorhandler
        && (
          <div style={{ display: (editPageErrorhandler) ? '' : 'none' }}>
            <ErrorHandler
              errorDisplayed={6}
              disableErrorHandler={disableErrorModal}
              errorNavToEditor
            />
          </div>
        )}
      <div className="dashboard_top_div">
        <div className="graph_div">
          <Graphtabs
            graphtabvalue={(graphvalue) => graphtabvalue(graphvalue)}
            selectedYear={(financialYearValue) => selectedYear(financialYearValue)}
          />
          <Graphcomponent
            graphtab={graphtabselected === undefined ? 'texts' : graphtabselected}
            selectedFYyear={selectedFinancialYear}
          />
        </div>
        <div className="pending_div">
          <Pendingtranslations />
        </div>
      </div>
      {selectedPopupValue.selectedlangvalue !== undefined && (
      <div className="dashboard_bottom_div">
        <div className="startpage_filters_div">
          <Filtercomponent
            ResultCount={dueindatacount.total_count}
            ShowCount={dueindatacount.total_count !== 0 && dueindatacount.total_count !== undefined}
            countloading={countload}
            Selectedvalues={(value) => Selectedvalues(value)}
            filterscleared={cleared}
            filtersclearedvalue={clearedvalues}
            dueincount={filteritemCount.dueinCount}
            statusCount={filteritemCount.statusCount}
            objCount={filteritemCount.objecttypeCount}
            categoryCount={filteritemCount.categoryCount}
          />
        </div>
        <div className="startpage_filters_div">
          <Diplayfilters
            displayfilter={(Updatedfilters, Updatedfiltersvalue) => displayfilter(
              Updatedfilters,
              Updatedfiltersvalue,
            )}
            heightoffilters={(height) => heightoffilters(height)}
            filters={selectedfilters}
            filtervalue={selectedfiltersvalue}
            clearfilters={resetFilters}
          />
        </div>
        {enableload === true && <div><Loadingcomponent loadingText={loadingText} /></div> }
        {enableload !== true && (
        <div>
          {dueindatacount.total_count === 0 ? ((selectedfilters.length === 1
                && selectedfilters.includes(overdueFilter)
                && sitsContext.previousStateDueinFilterShortName.includes(overdueFilter))
            ? (
              <div className="empty_grid">
                <SSRIcon className="checkMark_circle_color" paths={checkMark} />
                {negativeFlowMsg.noResultsIndashboardPageWithOverdueFilter}
              </div>
            ) : selectedfilters.length === 0 && dueindatacount.total_count === 0 ? (
              <div className="empty_grid">
                <SSRIcon className="checkMark_circle_color" paths={information} />
                {negativeFlowMsg.noResultsIndashboardPageWithNoFilters}
              </div>
            )
              : (
                <div className="empty_grid">
                  <SSRIcon className="information_circle_color" paths={information} />
                  {negativeFlowMsg.noResultsIndashboardPageWithoutOverdueFilter}
                </div>
              )

          )
            : (correlationId !== 1 && !countError ? (
              <div className="empty_grid">
                <SSRIcon className="icon_incorrect" paths={incorrect} />
                Error in loading data
              </div>
            ) : (
              <div className="Duein_table_padding ">
                <Dueindatatable
                  dueinTableData={dueindata}
                  dueInTotalCount={dueindatacount.total_count}
                  categoryMoreClick={categoryMoreClickHandler}
                />
              </div>
            )
            )}
        </div>
        )}
      </div>
      )}
      {enableload !== true && <div><BulkEdit /></div>}
    </div>
  );
}
