const SITS_API_URL = process.env.REACT_APP_SITS_API_URL;
const SITS_REDIRECT_URI = process.env.REACT_APP_SITS_REDIRECT_URI;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const AUTHORITY_URI = process.env.REACT_APP_AUTHORITY_URI;
const USER_ID = process.env.REACT_APP_USER_NETWORKID;
const CRED_KEY = process.env.REACT_APP_CRED_KEY;
const CRED_USER = process.env.REACT_APP_USERNAME;
const CRED_PASS = process.env.REACT_APP_CRD;
const USER_GROUP = process.env.REACT_APP_USER_USERGROUP;
const INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATIONKEY;
const ENV_NAME = process.env.REACT_APP_ENVIRONMENT;
export {
  SITS_API_URL,
  SITS_REDIRECT_URI,
  CLIENT_ID,
  AUTHORITY_URI,
  USER_ID,
  CRED_KEY,
  CRED_USER,
  CRED_PASS,
  USER_GROUP,
  INSTRUMENTATION_KEY,
  ENV_NAME,
};
