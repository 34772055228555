/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-quotes */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';
import Pill from '@ingka/pill';
import '@ingka/pill/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import uparrow from '@ingka/ssr-icon/paths/chevron-up';
import '@ingka/button/style.scss';
import Button from '@ingka/button';
import './Filtercomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/action-list/style.scss';
import '@ingka/focus/style.scss';
import ActionList, { ActionListItem } from '@ingka/action-list';
import { gObjectTypeValuesError, gObjectTypeValues } from '../../../api/Apirequests';
import ErrorHandler from '../../../common-components/errorhandler/ErrorHandler';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';
import { spaceAfterhundreds } from '../../../constants';
import SitsContext from '../../../context/SitsContext';
import objectTypeforStartPage from '../data/objecttypeFilterForDashboard.json';

let dropdownObjectTypevalues = [];

function Objecttypecomponent(props) {
  const ref = useRef(); // intializing the use Ref hook to capture the click outside the Modal
  const sitsContext = useContext(SitsContext);
  const [Checked, setChecked] = useState([]);
  // intailizing checked array to capture the checked(selected) filters
  const [filtername, setFiltername] = useState([]);
  // intailizing checked array to capture the selected filters name
  const [enable, setEnable] = useState(true);
  // intializing enable to render the component when there is a change in selected filters array
  // eslint-disable-next-line no-unused-vars
  const [newarray, setNewarray] = useState([]);
  // intializing newarray to update the filters values after the clear action is performed
  const [search, setSearch] = useState(false);
  // intializing the search to capture the search button function
  const [select, setSelect] = useState(false);
  // intializing the select to make the filter as selected
  const [isModalOpen, setModalOpen] = useState(false);
  // intializing the modalopen to display the dropdown of filters
  const [objectError, setObjectError] = useState(false);
  const [correlationId, setCorrelationId] = useState();
  // intializing to capture th correlation Id from filter api fail
  const [initStat, setInitStat] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [objectWithCount, setObjectWithCount] = useState([]);
  const [objectTypeList, setObjectTypeList] = useState(sitsContext.currentStatePageName !== 'dashboard' ? gObjectTypeValues : objectTypeforStartPage);

  useEffect(() => {
    if (search === true) {
      update(Checked, filtername);
      onClickClose();
    }
  }, [search]);
  // update function is called when there is any change in the search button in the dropdown

  useLayoutEffect(() => {
    // Checking if current page is dashboard, if it is, we don't execute this useeffect function
    if (sitsContext.pageRedirection && (sitsContext.currentStatePageName !== 'dashboard')) {
      setTimeout(() => {
        const filtervalues = {
          selectedfilters: sitsContext.previousStateObjectFilterShortName,
          selectedfiltersName: sitsContext.previousStateObjectFilter,
          searchselected: true,
        };
        setNewarray(sitsContext.previousStateObjectFilter);
        setChecked(sitsContext.previousStateObjectFilterShortName);
        setFiltername(sitsContext.previousStateObjectFilter);
        if ((sitsContext.previousStateObjectFilter.length !== 0)
        || (sitsContext.previousStateObjectFilterShortName.length !== 0)) {
          props.ObjectFilter(filtervalues);
        }
      }, 100);
    } else if (sitsContext.currentStatePageName === 'dashboard') {
      setTimeout(() => {
        const filtervalues = {
          selectedfilters: sitsContext.startPagepreviousStateObjectFilterShortName,
          selectedfiltersName: sitsContext.startPagepreviousStateObjectFilter,
          searchselected: true,
        };
        setNewarray(sitsContext.startPagepreviousStateObjectFilter);
        setChecked(sitsContext.startPagepreviousStateObjectFilterShortName);
        setFiltername(sitsContext.startPagepreviousStateObjectFilter);
        if ((sitsContext.startPagepreviousStateObjectFilter.length !== 0)
        || (sitsContext.startPagepreviousStateObjectFilterShortName.length !== 0)) {
          sitsContext.startPageupdatePreviousObjectFilter(
            sitsContext.startPagepreviousStateObjectFilter,
            sitsContext.startPagepreviousStateObjectFilterShortName,
          );
          props.ObjectFilter(filtervalues);
        }
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (sitsContext.currentStatePageName !== 'dashboard') {
      setObjectWithCount(gObjectTypeValues);
      setObjectTypeList(gObjectTypeValues);
    }
  }, [gObjectTypeValues]);

  useLayoutEffect(() => {
    if (typeof props.objectCount !== 'undefined') {
      setInitStat(false);
      objectTypeList.forEach((objType) => {
        if (objType.itemCount) delete (objType.itemCount);
        props.objectCount.forEach((objCount) => {
          if (
            objCount.groupName.toUpperCase()
            === objType.obj_short_name.toUpperCase()
          ) {
            objType.itemCount = objCount.itemCount;
          }
        });
      });
      dropdownObjectTypevalues = objectTypeList;
    }
  }, [props.objectCount]);

  useEffect(() => {
    if (props.filterarray !== undefined) {
      if (props.filterarray.length === 1 && props.filterarray[0] === 'Overdue') {
        update([], [], [], []);
      } else if (sitsContext.filterDisplayFlag
      && (props.filterarray !== null || props.filtervaluearray !== null)) {
        update(Checked, filtername, props.filterarray, props.filtervaluearray);
      }
    }
  }, [props.filterarray, props.filtervaluearray]);
  // update function is called when there is any change in the selected filters

  // on click functionality  for the Pill
  const onClickButton = () => {
    if (gObjectTypeValues.length === 0 && gObjectTypeValuesError.length !== 0) {
      setObjectError(!objectError);
      setCorrelationId(gObjectTypeValuesError.data.CorrelationID);
    }
    setModalOpen(!isModalOpen);
    setSelect(!select);
  };

  // close button functionality
  const onClickClose = () => {
    setObjectError(false);
    setModalOpen(false);
    setSelect(false);
  };

  // search button functionality
  const onClickSearch = () => {
    setSearch(true);
    sitsContext.updateEnableSearchbuttoninfilter(true);
  };

  // on clickoutside functinality using the useRef hook
  useOnClickOutside(ref, () => {
    setObjectError(false);
    setModalOpen(false);
    setSelect(false);
  });

  const handleChange = (e) => {
    sitsContext.updateInitialFilterFlag(true);
    const selectedvalue = e.target.value; // short name
    const selectedfilter = e.target.name; // full name
    sitsContext.updateFilterValues(selectedfilter);
    const currentIndex2 = Checked.indexOf(selectedvalue);
    // capturing the index value of target value
    const newChecked = [...Checked]; // the new checked array is appended with previous values
    const currentIndex1 = filtername.indexOf(selectedfilter);
    // capturing the index value of target name
    const newFilterName = [...filtername]; // the filtername array is appended with previous values

    // checking the index is -1 , if the index is -1 the value will be  pushed into the array
    if (currentIndex2 === -1 || currentIndex1 === -1) {
      newChecked.push(selectedvalue);
      newFilterName.push(selectedfilter);
    } else {
      newChecked.splice(currentIndex2, 1);
      newFilterName.splice(currentIndex1, 1);
    } // if the index is not  -1 the value will be spliced from the array
    setChecked(newChecked); // array is updated with new values
    setFiltername(newFilterName); // array is updated with new values
    update(newChecked, newFilterName);
  };

  function update(
    newChecked,
    newFilterName,
    cancelledarray,
    cancelledarrayvalue,
  ) {
    // sitsContext.updatePreviousObjectFilter(newChecked, newFilterName);
    if (cancelledarray === null) {
      setNewarray([]); // array is set to null if the clear all is clicked
    }
    const filtervalues = {
      selectedfilters:
        cancelledarrayvalue === null || cancelledarrayvalue === undefined
          ? newChecked
          : newChecked.filter((item) => cancelledarrayvalue.includes(item)),
      selectedfiltersName:
        cancelledarray === null || cancelledarray === undefined
          ? newFilterName
          : newFilterName.filter((item) => cancelledarray.includes(item)),
      searchselected: search,
    }; // array for combining all the filters data
    setEnable(true);
    setFiltername(filtervalues.selectedfiltersName);
    // filtersname array will be upadted with the new values
    setChecked(filtervalues.selectedfilters);
    // filtersvalues array will be upadted with the new values
    setNewarray(filtervalues.selectedfiltersName);
    // Checking if current page is dashboard, if it is, we don't update react context with current
    // data as this data is only updated for search page operations.If you do anything in dashboard,
    // it won't be updated
    if (sitsContext.currentStatePageName !== 'dashboard') {
      sitsContext.updatePreviousObjectFilter(
        filtervalues.selectedfiltersName,
        filtervalues.selectedfilters,
      );
    } else if (sitsContext.currentStatePageName === 'dashboard') {
      sitsContext.startPageupdatePreviousObjectFilter(
        filtervalues.selectedfiltersName,
        filtervalues.selectedfilters,
      );
    }
    sitsContext.updateintialloading(true);
    // array is updated with new values
    props.ObjectFilter(filtervalues);
    // filtersdata is passed to other components through the props method
    setSearch(false);
  }

  return (
    <div ref={ref}>
      <div>
        <div>
          <Pill
            className="Noto_font_family"
            // disabled={selectedPage === 'Dashboard' ? true : false}
            selected={!!select}
            small
            label={lablesAndnames.filter_labelForObjfilter}
            iconPosition="trailing"
            ssrIcon={select ? uparrow : downarrow}
            onClick={onClickButton}
          />
        </div>
      </div>

      {enable && (
        <div style={{ display: isModalOpen ? '' : 'none' }} className="parent_div_filter_position">
          <div className="dropdowndiv">
            <div>
              <ActionList className="action-lists Object_dropdown" id="ObjectType_filters" variant="compact">
                {dropdownObjectTypevalues.map((Object, index) => (
                  <ActionListItem
                    key={index}
                    control="checkbox"
                    controlProps={{
                      value: Object.obj_short_name,
                      name: Object.obj_full_name,
                      checked:
                      newarray.includes(Object.obj_full_name) === true,
                    }}
                    label={Object.obj_full_name}
                    onChange={handleChange}
                    quantityLabel={
                      Object.itemCount === undefined
                        ? 0 : spaceAfterhundreds(Object.itemCount)
                    }
                    disabled={
                      !!(Object.itemCount === undefined && !initStat)
                    }
                    className={initStat ? 'count-zero' : 'count-list'}
                  />
                ))}
              </ActionList>
            </div>

            <div>
              {(sitsContext.currentStatePageName !== 'dashboard' && (sitsContext.previousSearchData.search_value === null
                || sitsContext.previousSearchData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0)) && (
                <hr className="divider" />
              )}
              {(sitsContext.currentStatePageName !== 'dashboard' && (sitsContext.previousSearchData.search_value === null
                || sitsContext.previousSearchData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0)) && (
                <div className="objectdiv filter_button">
                  <Button
                    className="objecttype_filter"
                    onClick={onClickClose}
                    small
                    text={lablesAndnames.button_closeButtonText}
                  />
                  <Button
                    disabled={Checked.length === 0}
                    className="objecttype_filter"
                    small
                    text={lablesAndnames.button_searchButtonText}
                    onClick={onClickSearch}
                    type="primary"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {objectError && (<ErrorHandler errorDisplayed={4} correlationIdForFilter={correlationId} />
      )}
    </div>
  );
}
export default Objecttypecomponent;

// outside click functionality when the click event is captured outside the Modal
const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
