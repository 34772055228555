/* eslint-disable import/order */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { HashRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AUTHORITY_URI, CLIENT_ID, SITS_REDIRECT_URI } from './Config';
import store from './store/Store';
import { reactPlugin } from './applicationDataLogs/ApplicationInsights';

const root = ReactDOM.createRoot(document.getElementById('root'));

const oidcConfig = {
  authority: AUTHORITY_URI,
  client_id: CLIENT_ID,
  redirect_uri: SITS_REDIRECT_URI,
};

root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <React.StrictMode>
      <Router>
        <AuthProvider {...oidcConfig}>
          <Provider store={store}>
            <App />
          </Provider>
        </AuthProvider>
      </Router>
    </React.StrictMode>
  </AppInsightsContext.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
